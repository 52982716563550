import { Controller } from "@hotwired/stimulus"
// import { createCustomEvent } from '../utils'

export default class extends Controller {
  static targets = ["add_item", "template"]

  add_association(event) {
    // console.log("add clicou")
    event.preventDefault();
    const content = this.templateTarget.innerHTML
      .replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
   // this.dispatchAdded()
  }
  add_association2(event) {
    event.preventDefault();
    const content = this.template2Target.innerHTML
      .replace(/TEMPLATE_RECORD2/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
   // this.dispatchAdded()
  }

  remove_association(event) {
    event.preventDefault();
    const item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }

  dispatchAdded() {
    document.dispatchEvent(createCustomEvent('nested_form:association_added'))
  }
}
